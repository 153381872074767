import "./styles/index.scss";
import "smoothscroll-anchor-polyfill";

import React from "react";
import {
  VisitorIdentification,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import smoothscrollPolyfill from "smoothscroll-polyfill";
import MetaData from "./utils/MetaData";
import GtmScripts from "./utils/GtmScripts";
import DataLayerInit from "./utils/DataLayerInit";
import Placeholder from "./core/Components/Placeholder";

import MIAWChat from "./components/content/MIAWChat/MIAWChat";
import ChatSFHC from "./components/content/ChatSFHC/ChatSFHC";

const CDS = React.lazy(() => import("./themes/CDS"));
const COF = React.lazy(() => import("./themes/COF"));
const SOC = React.lazy(() => import("./themes/SOC"));
const REBRAND = React.lazy(() => import("./themes/REBRAND"));

/*
APP LAYOUT
This is where the app's HTML structure and root placeholders should be defined.

All routes share this root layout by default (this could be customized in RouteHandler),
but components added to inner placeholders are route-specific.
*/

// const Layout = ({route, sitecoreContext}) => {
class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMobileMenu: false,
      pageScrollable: true,
      isProfileLoaded: "",
      chatDisplay: false,
      chatType: "",
      chatRecipientOnly: true,
      chatAuthOnly: false,
      chatFixedPagePaths: [],
      chatFixedPageSetup: "",
      chatData: {},
    };
  }

  componentDidMount() {
    // if (window) {
    //   DataLayerInit(window, this.props.route, this.props.sitecoreContext)
    // }
    // Only continue if polyfills are actually needed
    if (!("scrollBehavior" in document.documentElement.style)) {
      smoothscrollPolyfill.polyfill();
    }
  }

  toggleMobileMenu = () => {
    this.setState((prevState) => {
      return {
        showMobileMenu: !prevState.showMobileMenu,
      };
    });
  };

  closeMobileMenu = () => {
    this.setState({ showMobileMenu: false });
  };

  setPageScrollable = (value) => {
    this.setState({ pageScrollable: value });
  };

  // if user is logged in, return the user id from header
  handleIsProfileLoaded = (value) => {
    this.setState({ isProfileLoaded: value });
  };

  // all chat config is returned form header
  handleChatData = (value) => {
    this.setState({
      chatDisplay: value.chatConfig.ChatDisplay?.value,
      chatType: value.chatConfig.chatType?.value,
      chatRecipientOnly: value.chatConfig.ChatRecipientOnly?.value,
      chatAuthOnly: value.chatConfig.ChatAuthOnly?.value,
      chatFixedPagePaths: value.chatConfig.chatFixedPagePaths,
      chatFixedPageSetup: value.chatConfig.ChatFixedPageSetup?.value,
      chatData: {
        miawChat: { ...value.miawChat },
        sfhcChat: { ...value.sfhcChat },
      },
    });
  };

  render() {
    const { showMobileMenu } = this.state;
    const { route, sitecoreContext, siteObject } = this.props;
    const { fields } = route;
    const classNameNoScroll = this.state.pageScrollable ? "" : "no-scroll";
    const webDir =
      sitecoreContext.metadata.webDirection === "Right to left" ? "rtl" : "ltr";
    const parentClassNames = `l-layout l-one-column cf ${
      route.templateName
    } ${classNameNoScroll} ${
      fields.isProfessional.value ? "professionals-page" : ""
    }`;

    const ThemeSelector = () => {
      // suspense not supported for ssr
      return typeof window === "undefined" ? null : (
        <React.Suspense fallback={null}>
          {fields.useRebrandStyling?.value && <CDS />}
          {fields.theme?.value === "cochlear-foundation" && <COF />}
          {fields.theme?.value === "standard-of-care" && <SOC />}
          {fields.theme?.value === "cochlear-rebrand" && <REBRAND />}
        </React.Suspense>
      );
    };

    const hasTheme = fields.useRebrandStyling?.value || fields.theme?.value;

    const DisplayMiawChat = () => {
      const { chatData } = this.state;
      return <MIAWChat fields={chatData.miawChat} />;
    };

    const DisplaySfhcChat = () => {
      const { chatData } = this.state;
      return <ChatSFHC fields={chatData.sfhcChat} />;
    };

    const DisplayChat = () => {
      const { chatType } = this.state;
      if (chatType.toLowerCase() === "miaw") {
        return DisplayMiawChat();
      } else if (chatType.toLowerCase() === "chatbot") {
        return DisplaySfhcChat();
      }
      return null;
    };

    const ChatConfig = () => {
      const { siteObject } = this.props;
      const {
        chatFixedPageSetup,
        chatDisplay,
        chatRecipientOnly,
        isProfileLoaded,
        chatAuthOnly,
        chatFixedPagePaths,
      } = this.state;

      const path =
        siteObject &&
        `/${siteObject.country}/${siteObject.lang}${siteObject.url}`; // siteObject.url includes leading '/'

      const isProfessionalsRoute = path
        ? path.startsWith(
            `/${siteObject.country}/${siteObject.lang}/professional`,
          )
        : false;

      let pathUrls =
        chatFixedPagePaths.length > 0
          ? chatFixedPagePaths.map(({ fields }) => fields.link.value.href)
          : [];

      // compare the current page with the list of pages in the fixed page paths list
      // if it exists, show the chat defined in the fixed page setup (eg, miaw or chatbot)
      if (chatFixedPageSetup !== "" && pathUrls.includes(path)) {
        if (chatFixedPageSetup.toLowerCase() === "miaw") {
          return DisplayMiawChat();
        } else if (chatFixedPageSetup.toLowerCase() === "chatbot") {
          return DisplaySfhcChat();
        } else {
          return null;
        }
      } else if (!pathUrls.includes(path) && chatDisplay) {
        // if current page isnt in the list, and chat display is set to true

        if (chatRecipientOnly) {
          // chat setup is non-professionals sub-directory only
          if (isProfessionalsRoute) {
            // current route is part of professionals sub-directory so dont show chat here
            return null;
          } else {
            // not part of professionals sub-directory
            if (chatAuthOnly) {
              // chat setup is for authenticated users only
              if (isProfileLoaded) {
                // show chat if user is authenticated
                return DisplayChat();
              } else {
                // not authenticated so dont show chat
                return null;
              }
            } else {
              // chat for non authenticated and authenticated users
              return DisplayChat();
            }
          }
          // not concerned about recipient/professionals route
        } else {
          if (chatAuthOnly) {
            // chat setup is for authenticated users only
            if (isProfileLoaded) {
              // show chat if user is authenticated
              return DisplayChat();
            } else {
              // not authenticated so dont show chat
              return null;
            }
          } else {
            // chat for non authenticated and authenticated users
            return DisplayChat();
          }
        }
      }
    };

    return (
      <React.Fragment>
        <ThemeSelector />
        <MetaData />
        <DataLayerInit route={route} context={sitecoreContext} />
        <GtmScripts
          gtmIdGlobal={sitecoreContext.metadata.gtmContainerGlobal}
          gtmIdRegional={sitecoreContext.metadata.gtmContainerRegion}
        />
        <VisitorIdentification />

        <div
          dir={webDir}
          data-template={route.templateName}
          className={`main-content ${
            fields.useRebrandStyling?.value ? "" : parentClassNames
          }`}
          // theme css loads from separate chunk due to React.lazy, hide page until ready
          style={hasTheme ? { visibility: "hidden" } : undefined}
        >
          <main
            id="main"
            tabIndex="-1"
            // element with theme class name must be a child of the element with dir attribute
            className={`${fields.useRebrandStyling?.value ? "" : "l-main "}${
              fields.theme?.value || ""
            }`}
          >
            <Placeholder
              name="global-header"
              rendering={route}
              siteObject={siteObject}
              isProfessional={fields.isProfessional.value}
              showMobileMenu={this.state.showMobileMenu}
              toggleMobileMenu={this.toggleMobileMenu}
              closeMobileMenu={this.closeMobileMenu}
              setPageScrollable={this.setPageScrollable}
              handleIsProfileLoaded={this.handleIsProfileLoaded}
              handleChatData={this.handleChatData}
            />
            <article
              id="article"
              className={`article ${
                fields.useRebrandStyling?.value ? "" : "l-article"
              } ${showMobileMenu ? "is-hidden" : ""} `}
            >
              <Placeholder
                name="header"
                rendering={route}
                isProfessional={fields.isProfessional.value}
                showMobileMenu={this.state.showMobileMenu}
              />
              {(sitecoreContext.pageEditing ||
                (!!route.placeholders.main &&
                  !!route.placeholders.main.length)) && (
                <div
                  className={`${
                    fields.useRebrandStyling?.value ? "" : "l-padding"
                  }`}
                >
                  <div
                    className={`${
                      fields.useRebrandStyling?.value ? "" : "l-content-column"
                    }`}
                  >
                    <Placeholder
                      name="main"
                      rendering={route}
                      isProfessional={fields.isProfessional.value}
                      showMobileMenu={this.state.showMobileMenu}
                    />
                  </div>
                </div>
              )}
              <Placeholder
                name="footer"
                rendering={route}
                isProfessional={fields.isProfessional.value}
                showMobileMenu={this.state.showMobileMenu}
              />
            </article>
            <Placeholder
              name="global-footer"
              rendering={route}
              isProfessional={fields.isProfessional.value}
              showMobileMenu={this.state.showMobileMenu}
            />
            {ChatConfig()}
          </main>
        </div>
      </React.Fragment>
    );
  }
}

export default withSitecoreContext()(Layout);
